export const ROUTES = {
  dashboard: '/',
  media: '/media',
  upload: '/media/new',
  campaignMediaUpload: '/media/campaign-upload',
  slides: '/slides/new',
  campaigns: '/campaigns',
  campaignCreate: '/campaigns/new',
  forbidden: `/forbidden`,
};
